

       <nb-card>
        <div  (click)="close()">
            <i title="Back To Dashboard" class="fas fa-arrow-left fa-2x fa-pull-right fa-border"></i>
          </div>
     
    <div _ngcontent-jme-c153="" class="faq-section">
        <div _ngcontent-jme-c153="" class="container-fluid">
            <div _ngcontent-jme-c153="" class="row">
                
                <div _ngcontent-jme-c153="" class="col-md-12">
                    <h1 _ngcontent-jme-c153="" class="faq-hd">FAQ Section</h1>
                    <!-- <p _ngcontent-jme-c153="" class="question">Q. How long will my request take to process ?</p>
                    <p _ngcontent-jme-c153="" class="answer">A. Requests typically take 7-10 working days to process. This
                        may sometimes get delayed in case of holidays in the University / College, or if the record
                        requested for is old.</p> -->
                    <p _ngcontent-jme-c153="" class="question">Q. How do I know my request has been accepted ?</p>
                    <p _ngcontent-jme-c153="" class="answer">A. Any request will only be declined if the documents you have
                        uploaded are either incomplete, or unclear. In either of these situations, you will receive an email
                        from us on your registered email id, stating the pending information required from your end. Once we
                        receive the pending information, the request will get processed.</p>
                    <p _ngcontent-jme-c153="" class="question">Q. How do I know my payment has been received ?</p>
                    <p _ngcontent-jme-c153="" class="answer">A. As soon as your payment is received by us from our online
                        payment gateway partner, you will receive a receipt for the same on your registered email id. The
                        receipt can also be download from your account as well.</p>
                    <p _ngcontent-jme-c153="" class="question">Q. How is my request going to be processed ?</p>
                    <p _ngcontent-jme-c153="" class="answer">A. For each request, we access the University / College
                        archives to search for the information. Once this is received, we compare it to the documents
                        uploaded by you, and send you a report on your registered email id.</p>
                    <p _ngcontent-jme-c153="" class="question">Q. How do I track the status of my request ?</p>
                    <p _ngcontent-jme-c153="" class="answer">A. You can log into this website, with the same user id and
                        password you used for registration, to get the latest update on your request.</p>
                    <p _ngcontent-jme-c153="" class="question">Q. On the website, my request shows as “Completed”, but I
                        have not received any report.</p>
                    <p _ngcontent-jme-c153="" class="answer">A. Please check in your registered email, in the Spam or Junk
                        folders in case the report has gone there. If you still don’t see the report, write to us at
                        soutranscript@etranscript.in</p>
                    <p _ngcontent-jme-c153="" class="question">Q. The transaction failed while I uploaded my request, but
                        the money got debited from my account. What should I do ?</p>
                    <p _ngcontent-jme-c153="" class="answer">A. Do not worry. We will get a report of the same at our end,
                        and the money will get automatically refunded to your account within 7 working days. In case you do
                        not receive it within that period, you can write to us through the Contact Us Option or submit your
                        query through the form provided.</p>
                    <p _ngcontent-jme-c153="" class="question">Q. Can I ask for a refund once I have placed a request ?</p>
                    <p _ngcontent-jme-c153="" class="answer">A. Unfortunately, no. Once we receive your completed request
                        and your corresponding payment, the request is already under process. We may only refund your
                        payment in extremely rare circumstances, where we are unable to process the request due to some
                        failure at our end.</p>
                    <p _ngcontent-jme-c153="" class="question">Q. How do I know the report I receive is genuine ?</p>
                    <p _ngcontent-jme-c153="" class="answer">A. As you will see once you receive the report, it carries the
                        signature of the designated official from the University.</p>
                    <p _ngcontent-jme-c153="" class="question">Q. I do not wish to follow the online process. Can I approach
                        the University directly for my request ?</p>
                    <p _ngcontent-jme-c153="" class="answer">A. This online process has been adopted by the University in
                        order to make the processing of requests faster, hassle-free and transparent for students. With the
                        adopting of the online process, these Universities / Colleges will have stopped accepting any manual
                        request. However, if you still wish to approach the University and attempt the same, you are free to
                        do so.</p>
                    <p _ngcontent-jme-c153="" class="question">Q. In case of any queries / complaints / clarifications, whom
                        should I approach ?</p>
                    <p _ngcontent-jme-c153="" class="answer">A. ou may write to us through the Contact Us Option or submit
                        your query through the form provided on the site and we will respond to you within 2 working days of
                        receiving your query.</p>
                </div>
            </div>
        </div>
    </div>
</nb-card>